<template>
  <div class="dimbox">
    <div class="mHeader">
      <p>팀 관리</p>
    </div>
    <div class="mBody">
      <div class="registerBox">
        <div class="flexL">
          <p>팀명</p>
          <input type="text" v-model="teamInfo.teamName" />
        </div>
      </div>
      <div class="buttonWrap">
        <button @click="submit()" class="main">수정</button>
        <button @click="close" class="sub">취소</button>
      </div>
    </div>
  </div>
</template>
<script>
import { updateTeam } from "@/api/index";
export default {
  props: ["teamInfo"],
  data() {
    return {
      teamName: "",
    };
  },
  created() {},
  activated() {
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      if (this.teamInfo.teamName == "") {
        return alert("팀명을 입력해 주세요.");
      }
      let data = {
        teamName: this.teamInfo.teamName,
        teamId: this.teamInfo._id,
      };
      updateTeam(data).then((res) => {
        if (res.data.status == 200) {
          alert("팀 정보가 수정 되었습니다.");
          
          this.$emit("getTeamList")
          this.$emit("close");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    // getTeamInfo() {
    //   fetchTeamDetail(this.teamId).then((res) => {
    //     if (res.data.status == 200) {
    //       this.editMode = true;
    //       this.teamName = res.data.data.teamName;
    //     } else {
    //       let message = res.data.message;
    //       alert(message);
    //     }
    //   });
    // },
  },
};
</script>
