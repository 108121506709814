<template>
  <div class="dimbox">
    <div class="mHeader">
      <p>신규팀 생성</p>
    </div>
    <div class="mBody">
      <div class="registerBox">
        <div class="flexL">
          <p>팀명</p>
          <input type="text" v-model="teamName" />
        </div>
      </div>
      <div class="buttonWrap">
        <button class="main" @click="submit">생성</button>
        <button @click="close" class="sub">취소</button>
      </div>
    </div>
  </div>
</template>
<script>
import { createTeam } from "@/api/index";
export default {
  data() {
    return {
      teamName: "",
    };
  },

  activated() {
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      if (this.teamName == "") {
        return alert("팀명을 입력해 주세요.");
      }
      let data = {
        teamName: this.teamName,
      };
      createTeam(data).then((res) => {
        if (res.data.status == 200) {
          alert("팀 등록이 완료 되었습니다.");
          this.$emit("getTeamList");
          this.$emit("close");
          
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
   
  },
};
</script>
