<template>
  <div class="contents">
    <div class="title flexB">
      <h2>팀관리</h2>
      <p>{{ name }} ({{ team }})</p>
    </div>
    <div class="box one filter flexR">
      <div class="flex searching">
        <!-- <select v-model="inputMode">
          <option selected value="teamName">팀명</option>
        </select> -->
        <input
          type="text"
          v-model="searchWord"
          @keyup.enter="search"
          placeholder="팀명을 입력해 주세요."
        />
        <div class="buttons">
          <button @click="search" class="search">
            <span class="material-icons-outlined"> search </span>
          </button>
          <button @click="reset" class="reset">
            <span class="material-icons-outlined"> refresh </span>
          </button>
        </div>
      </div>
    </div>
    <div class="box table one">
      <div class="top flexB">
        <div class="tit flex">
          <h2 class="bold">팀 리스트</h2>
          <p>
            <span class="count">{{ total }}</span
            >개의 검색결과가 있습니다.
          </p>
        </div>
        <div class="buttons">
          <button class="registerBtn" @click="handleCreateModal()">
            신규팀 생성
          </button>
        </div>
      </div>
      <table class="styled-table">
        <colgroup>
          <col style="width: 15%" />
          <col style="width: 60%" />
          <col style="width: 25%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>팀명</th>
          <th>상태</th>
        </tr>
        <tr v-for="(data, i) in teamList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>{{ data.teamName }}</td>
          <td>
            <div class="status">
              <button class="edit" @click="handleEditModal(data)">
                <span class="material-icons-outlined"> edit </span>
              </button>
              <button @click="submit(data._id)" class="delete">
                <span class="material-icons-outlined"> delete </span>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="teamList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="visible">
        <EditTeam
          v-if="editVisible"
          @close="close"
          @getTeamList="getTeamList"
          :teamInfo="teamInfo"
        />
        <CreateTeam
          @getTeamList="getTeamList"
          v-if="createVisible"
          @close="close"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

import { format } from "@/mixins/format";
// import { deleteUser } from "@/api/index";
import { deleteTeam, fetchTeamList } from "@/api/index";
import EditTeam from "@/components/pop/EditTeam.vue";
import CreateTeam from "@/components/pop/CreateTeam.vue";
import { mapState } from "vuex";
export default {
  components: { CreateTeam, EditTeam },
  mixins: [format],
  name: "TeamManage",
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      teamList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      teamName: "",
      teamId: "",
      currentComponent: "",
      visible: false,
      editVisible: false,
      createVisible: false,
      teamInfo: {},
    };
  },
  computed: {
    ...mapState(["name", "team", "_id", "isAdmin"]),
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "1-2");
    setTimeout(() => {
      if (this.isAdmin == false) {
        this.$router.push("/");
      }
    }, 500);
    this.getTeamList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.searchWord = "";
      this.teamList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getTeamList();
    },
    search() {
      if (this.searchWord == "") {
        return alert("검색어를 입력해 주세요.");
      }
      this.getTeamList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },
    handleDeleteUser(id) {
      let result = confirm("회원을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteTeam(id).then((res) => {
        if (res.data.status == 200) {
          this.getTeamList();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    getTeamList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
      };
      fetchTeamList(params).then((res) => {
        if (res.data.status == 200) {
          this.teamList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },

    handleCreateModal() {
      this.visible = true;
      this.createVisible = true;
    },
    handleEditModal(teamInfo) {
      this.teamInfo = teamInfo;
      this.visible = true;
      this.editVisible = true;
    },
    close() {
      this.visible = false;
      this.createVisible = false;
      this.editVisible = false;
    },
    submit(id) {
      let result = confirm("팀을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      let data = {
        teamId: id,
      };
      deleteTeam(data).then((res) => {
        if (res.data.status == 200) {
          alert("팀 삭제가 완료 되었습니다.");
          // document.location.href = "/admin/teammanage";
          this.getTeamList();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
